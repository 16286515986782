//
// Display
// --------------------------------------------------

.overflow-y-auto {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

// Full height with scroll on large screens
@include media-breakpoint-up(lg) {
  .h100-scroll {
    height: 100vh;
    overflow-y: auto;
  }
}
