

.select2-container--default {

    .select2-selection--single {
        @extend .form-control;
        min-height: 2.375rem;
        border-color: $border-color;

        .select2-selection__rendered {
            padding: 0 1.25rem 0 0;
            line-height: 1.5rem;
        }

        .select2-selection__arrow {
            height: 100%;
        }
    }

    .select2-selection--multiple {
        @extend .form-control;
        border-color: $border-color;
        min-height: 2.375rem;
        padding: calc($input-padding-y - 5px) $input-padding-x $input-padding-y $input-padding-x;

        .select2-search__field {
            font-family: var(--bs-body-font-family);
        }

        .select2-selection__choice {
            @extend .bg-light, .ps-2, .pe-3;
            border-radius: 0.125rem;
            border: none;
            &:first-child {
                margin-left: 0;
            }
        }

        .select2-selection__choice__remove {
            right: 0;
            left: auto;
            border-right: none;
            @extend .px-2;
        }
    }

    &.select2-container--focus .select2-selection--multiple{
        border-color: $primary;
    }

    /* Floating */
    .form-floating &{
        .select2-selection--single {
            min-height: $form-floating-height;
            padding-top: $form-floating-input-padding-t;
        }
        .select2-selection--multiple {
           min-height: $form-floating-height;
            padding-top: calc($form-floating-input-padding-t - 5px);
            padding-bottom: calc($form-floating-input-padding-b - 2px);
            line-height: 1.4rem;

            .select2-selection__choice__display{
                 font-size: 0.9rem;
            }
        }
    }

    .select2-search--dropdown .select2-search__field {
        font-family: var(--bs-body-font-family) !important;
        border-color: $border-color;
    }

    .select2-dropdown {
        border-color: $border-color;
        @extend .shadow-sm;
        z-index: 1080;
    }
}
