.snackbar {
    z-index: 1060;
    margin: 8px;
    display: none;
    position: fixed;
    right: 0;
    bottom: 2rem;
    left: 0;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    pointer-events: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .snb-surface {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transform: scale(.8);
      -ms-transform: scale(.8);
      transform: scale(.8);
      opacity: 0;
      min-width: 320px;
      max-width: 672px;
      -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
      box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
      border-radius: 4px;
    
    }
  
    .snb-label {
      font-family: Roboto, sans-serif;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-size: .875rem;
      line-height: 1.25rem;
      font-weight: 400;
      letter-spacing: .0178571429em;
      text-decoration: inherit;
      text-transform: inherit;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin: 0;
      padding: 12px 14px;
    }
  
    .snb-close {
      width: 36px;
      height: 36px;
      border: none;
      border-radius: 50%;
      outline: none;
      margin-right: 8px;
      background-color: transparent;
      text-decoration: none;
      &:hover {
        background-color: rgba(0,0,0,.2);
      }
  
      span{
        line-height: 0;
        font-size: 1.5rem;
        opacity: 0.7;
        &:hover{
          opacity: 1;
        }
      }
    }
  
    &.open {
      display: -ms-flexbox;
      display: flex;
  
      .snb-surface {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: opacity .15s cubic-bezier(0, 0, .2, 1) 0ms, -webkit-transform .15s cubic-bezier(0, 0, .2, 1) 0ms;
        transition: opacity .15s cubic-bezier(0, 0, .2, 1) 0ms, -webkit-transform .15s cubic-bezier(0, 0, .2, 1) 0ms;
        -o-transition: opacity .15s 0ms cubic-bezier(0, 0, .2, 1), transform .15s 0ms cubic-bezier(0, 0, .2, 1);
        transition: opacity .15s cubic-bezier(0, 0, .2, 1) 0ms, transform .15s cubic-bezier(0, 0, .2, 1) 0ms;
        transition: opacity .15s cubic-bezier(0, 0, .2, 1) 0ms, transform .15s cubic-bezier(0, 0, .2, 1) 0ms, -webkit-transform .15s cubic-bezier(0, 0, .2, 1) 0ms;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  